import { Exact, MatchmakeQuery, MatchmakeRequest, useMatchmakeLazyQuery } from 'generated/graphql';

import { LazyQueryHookOptions } from '@apollo/client';
import { config } from 'config';
import { useFeatureToggle } from 'lib/useFeatureToggles';
import { FEATURE_TOGGLE_PLAY_ROUTE } from 'constants/featureToggle.constants';

export const useMatchMake = (
  options?: LazyQueryHookOptions<
    MatchmakeQuery,
    Exact<{
      request: MatchmakeRequest;
    }>
  >
) => {
  const playRouteEnabled = useFeatureToggle(FEATURE_TOGGLE_PLAY_ROUTE).isEnabled;

  const redirect = (sessionUrlStr: string) => {
    const sessionUrl = new URL(sessionUrlStr);
    const daoUrl = new URL(config.settings.daoUrl);

    if (playRouteEnabled && sessionUrl.origin === daoUrl.origin && sessionUrl.pathname === daoUrl.pathname) {
      // Not using router.push since it isn't a route handled by Next.js
      window.location.href = `/play/${sessionUrl.search}`;
    } else {
      window.location.href = sessionUrlStr;
    }
  };

  const [matchMake, matchMakeResult] = useMatchmakeLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const sessionUrl = data?.matchMake?.sessionUrl;

      if (sessionUrl) {
        redirect(sessionUrl);
      }
    },
    ...options,
  });

  const handleMatchMake = (request: MatchmakeRequest) => {
    return matchMake({
      variables: {
        request: { clientNetworkProtocolVersion: null, clientVersion: null, ...request },
      },
    });
  };

  return [handleMatchMake, matchMakeResult] as const;
};
