import { config } from 'config';
import { prettyEnv } from './prettyEnv';

export type Channel = 'stable' | 'rc' | 'nightly' | 'dev';

export const releaseChannels = {
  prod: 'stable',
  stage: 'rc',
  dev: 'dev',
};

export const getDefaultReleaseChannel = (): Channel => {
  return releaseChannels[prettyEnv(config.environment)];
};
