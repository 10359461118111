export const formatNumber = (number: number): string => {
  if (number < 1000) {
    return number.toString();
  }
  // One decimal on K
  if (number < 1000000) {
    return `${Math.floor(number / 100) / 10}K`;
  }

  if (number < 1000000000) {
    // Two decimals on M
    return `${Math.floor(number / 10000) / 100}M`;
  }

  // Two decimals on B
  return `${Math.floor(number / 10000000) / 100}B`;
};

/**
 * Prettifies numbers: 14000000 -> 14 000 000
 *
 * @param number
 * @returns {string} prettified number
 */
export const numberWithSpaces = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
