import { Select } from 'components/inputs/Select/Select';
import { TextInput } from 'components/inputs/TextInput/TextInput';
import React from 'react';
import { Channel } from 'utils/releaseChannel';

export type EngineChannelProps = {
  engineChannel: Channel;
  setEngineChannel: (engineChannel: Channel) => void;
};

export const EngineChannel: React.FC<EngineChannelProps> = ({ engineChannel, setEngineChannel }) => (
  <div className="flex flex-col gap-4">
    <Select
      aria-label="engine version"
      onChange={e => setEngineChannel(e.target.value as Channel)}
      defaultValue={engineChannel}>
      <option value="stable">Stable</option>
      <option value="rc">Release candidate</option>
      <option value="dev">Master build</option>
      <option value="nightly">Nightly</option>
    </Select>
    <TextInput
      aria-label="custom channel"
      placeholder="or custom channel"
      onChange={e => setEngineChannel(e.target.value as Channel)}
    />
  </div>
);
