export const formatPercentage = (number: number): string => {
  if (number === 0) {
    return '0%';
  }

  // Show three decimal places if it's below 0.001
  if (number < 0.001) {
    return `${Math.floor(number * 1000000) / 1000}%`;
  }

  // Show two decimal places if it's below 0.01
  if (number < 0.01) {
    return `${Math.floor(number * 10000) / 100}%`;
  }

  // Show one decimal place if it's below 0.1
  if (number < 0.1) {
    return `${Math.floor(number * 1000) / 10}%`;
  }
  return `${Math.floor(number * 100)}%`;
};
