import { EngineVersionModal } from 'components/legacy/modals/EngineVersionModal/EngineVersionModal';
import { config } from 'config';
import { SessionType } from 'generated/graphql';
import { useModal } from 'lib/useModal';
import { prettyEnv } from 'utils/prettyEnv';
import { getDefaultReleaseChannel } from 'utils/releaseChannel';
import { useDive } from './useDive';
import { useMatchMake } from './useMatchMake';
import { useMe } from './useMe';

export const useGetOrCreateDaoSession = () => {
  const { me } = useMe();
  const [matchMake, matchmakeRequest] = useMatchMake();
  const showDevFeatures = prettyEnv(config.environment) !== 'prod';
  const dive = useDive();

  const joinSession = (projectId: string, channel: string) => {
    if (!matchmakeRequest.called) {
      dive.trackGameInit(projectId);
    }

    if (!me?.isRegistered) {
      // Increment the number of worlds visited
      const worldsVisitedItem = localStorage.getItem('worlds_visited');

      const worldsVisited = parseInt(worldsVisitedItem || '0', 10);
      localStorage.setItem('worlds_visited', (worldsVisited + 1).toString());
    }

    matchMake({
      projectId,
      type: SessionType.Play,
      channel,
      clientVersion: null,
      clientNetworkProtocolVersion: null,
    });
  };

  const engineVersionModal = useModal(
    <EngineVersionModal handleJoin={(projectId, channel) => joinSession(projectId, channel)} />
  );

  const loadAndJoinSession = (projectId: string, channel?: string) => {
    channel = channel ?? getDefaultReleaseChannel();

    if (showDevFeatures) {
      engineVersionModal.open({ projectId });
      return;
    }

    joinSession(projectId, channel);
  };

  return { loading: matchmakeRequest.loading, loadAndJoinSession };
};
