import { Button } from 'components/common/buttons/Button';
import { EngineChannel } from 'components/inputs/EngineChannel';
import { Heading3 } from 'components/typography';
import { useState } from 'react';
import { Channel, getDefaultReleaseChannel } from 'utils/releaseChannel';
import { Modal } from '../Modal';

export const EngineVersionModal = ({
  handleJoin,
  close,
  props,
}: {
  close?: () => void;
  handleJoin: (projectId: string, channel: Channel) => void;
  props?: { projectId: string };
}) => {
  const { projectId } = props ?? {};

  const [engineChannel, setEngineChannel] = useState<Channel>(getDefaultReleaseChannel());

  const onClick = () => {
    handleJoin(projectId || '', engineChannel);
    close?.();
  };
  return (
    <Modal>
      <Heading3 className="mb-8 text-center">Select engine version</Heading3>
      <EngineChannel engineChannel={engineChannel} setEngineChannel={setEngineChannel} />
      <div className="flex justify-center mt-4">
        <Button variety="primary" size="medium" onClick={onClick} text="Go!" />
      </div>
    </Modal>
  );
};
